
















































// Core
import {Component, Vue} from 'vue-property-decorator'
import {namespace} from 'vuex-class'

// Store
import {userNamespace} from '@store/user'

// Types
import {TeamActions, TeamMutations} from '@store/team/types'

// Interfaces
import {Meta, Paginate, RequestParams} from '@store/interfaces'
import {Order, Orders, Team, TeamState} from '@store/team/interfaces'

// Modules
const NSTeam = namespace('teamModule')

@Component({
  name: 'ProjectTeam',

  components: {
    'v-scroll-list': () => import('@components/ScrollList.vue'),
    'v-specialist': () => import('@/components/Specialist.vue'),
    'v-order': () => import('@components/Order.vue'),
    'v-dialog-search-specialist': () => import('@/components/dialogs/DialogSearchSpecialist.vue'),
    'v-dialog-invite-specialist': () => import('@/components/dialogs/DialogInviteSpecialist.vue'),
  },
})
export default class ProjectTeamComponent extends Vue {
  @userNamespace.State('selectedProject')
  private projectId!: number

  @NSTeam.State((state: TeamState) => state.teamList)
  private team!: Team[]

  @NSTeam.State((state: TeamState) => state.team.meta)
  private meta!: Meta

  @NSTeam.State((state: TeamState) => state.orders)
  private orders!: Orders[]

  @NSTeam.Mutation(TeamMutations.M_CLEAR_TEAM)
  private clearTeam!: () => void

  @NSTeam.Action(TeamActions.A_FETCH_TEAM)
  private fetchTeam!: (params: RequestParams) => Promise<Paginate<Team>>

  @NSTeam.Action(TeamActions.A_FETCH_ORDERS)
  private fetchOrders!: (params: RequestParams) => Promise<Order[]>

  private visibleDialogSearchSpecialist: boolean = false
  private visibleDialogInviteSpecialist: boolean = false

  private loading: boolean = false

  async mounted() {
    this.loading = true

    this.clearTeam()
    await this.fetchTeam({projectId: this.projectId})
    this.fetchOrders({projectId: this.projectId})

    this.loading = false
  }

  private loadMore() {
    if (this.meta.lastPage > this.meta.currentPage) {
      this.fetchTeam({
        projectId: this.projectId,
        page: this.meta.currentPage + 1,
      })
    }
  }
}
